@font-face {
    font-family: 'moon';
    src: local('moon'), url(./fonts/Moon-bold.otf) format('opentype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
  }

html {
    font-family: moon, serif;
}

.main-layout {
    /* display: flex; */
    padding-top: 17%;   
}

@media (max-width: 1200px) {
    .main-layout {
       padding-top: 2%;   
    }
}


@media (max-width: 800px) {
    .main-layout {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center;
    }

    .next-airdrop {
        color: white !important;
    }
}
.next-drop {
    padding-right: 30px;
}

.timer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.next-airdrop {
    font-size: 32px;
    color:#3A2F60
}

.next-drop, .claim{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.timer-value {
    width: 350px;
    background-image: linear-gradient(#3D3062, #783C50);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 64px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-top: 8px;
}

.timer-value-inner{
    width: 350px;
    background-image: linear-gradient(#3D3062, #783C50);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 64px;
    border-radius: 20px;
}

.next-airdrop-label {
    font-size: 22px;
}

.claim-button {
    width: 150px;
    height: 50px;
    font-size: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-image: linear-gradient(#FA7F2F, #AA432E); */
    background-color: #F87E2F;
    display: inline-flex;
    border-radius: 20px;
    margin-top: 10px;
    cursor: pointer;
    
}
.claim-button:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 0 0);
  }

.amount {
    background-color: #3A2F60;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    font-size: 22px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 0 20px 20px  0;
}

.moon-logo > img{
    width: 90px;
    display: inline;
    position:relative;
    left: 15px;
}

.show  {
    display: unset;
}

.hide {
    display: none;
}

.moonrace {
    font-size: 35px;
}