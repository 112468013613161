.body {
    background: url(./images/swap_background.png) no-repeat center center fixed !important;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100vw;
    height: 100vh;
    opacity: 50%;
}

.swap-flex {
    display: flex;
    justify-content: flex-end;
    padding-right: 200px;
    padding-top: 200px;
    align-items: center;
}

.buy-flex {
    display: flex;
    justify-content: flex-end;
    padding-right: 200px;
    padding-top: 200px;
    align-items: center;
}

.swap-container {
    width: 700px;
    background-color: #FFFBEB;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.top {
    border-radius: 20px 20px 0 0;
}

.bottom {
    border-radius: 0 0 20px 20px;
}

.top, .bottom{
    background-color: #E9DFB3;
    width: 97%;
    display: flex;
    height: 150px;
    justify-content: space-between;
    align-items: center;
}

.submit-btn {
    margin: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    font-size: 22px;
}

.swap-title {
    font-size: 14px;
    color: #22272C;
    align-self: start;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.value {
    background-color: #C5BD9A;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    font-size: 28px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 10px;
    padding-top: 18px;
    padding-bottom: 10px;
}

.max {
    cursor: pointer;
}

.right > .value {
    background-color: #ABA380;

}

.left {
    padding-left: 50px;
    color: #22272C;
}

.right {
    display: flex;
    flex-direction: column;
    align-items: end;
    color: #22272C;
    padding-right: 50px;

}

.left-value {
    font-size: 28px;
}

.left-balance {
    font-size: 18px;
}

.bottom {
    background-color: #CFC59A;
    width: 97%;
}

.value  {
    height: 40px;
}

.submit-btn {
    background-color: #173555;
    width: 97%;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.submit-btn:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0 0);
  }

input {
    all: unset;
    width: 80%;
    text-align: right;
}

.refresh {
    cursor: pointer;
    position: absolute;
    top: 92%;
    left: 95%;
    display: flex;
    flex-direction: column; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* NAVIGATION */
.nav-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav-link:hover {
    /* color: #22272C; */
    background-color: #63C269;
    color: white;
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.3) 0 0); */
 }

.nav-link  {
    background-color: white;
    width: 100px;
    height: 50px;
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

}
.nav-link > Link:link {

    text-decoration: none;
}

.alien {
    position: relative;
    right:  75px;
    bottom:  5px;

}

.logo {
    padding-top: 10px;
}

/* teams */

.teams {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}


.blue {
    padding: 10px;
    background-color: white;
    display: flex;
    border-radius: 10px;
    color: #3BB5E3;
}

.address {
    font-size: 12px;
}

.orange-team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 15%;
}

.orange-team {
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #E98146;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 24px;
    text-align: center;
}

.blue-team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 15%;
}

.blue-team {
    background-color: #3BB5E3;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 24px;
    text-align: center;
}

.orange {
    flex-direction: column;
    padding: 10px;
    background-color: white;
    display: flex;
    border-radius: 10px;
    color: #E98146;
}

.blue, .orange {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.airdrop-ready {
    font-size: 20px;
}

.blue > .team-label {
    color: #187799;
}

.orange > .team-label {
    color: #c55c1f;
}

.team-amount {
    font-size: 32px;
}

.error {
    position: absolute;
    display: flex;
    top: 90%;
    left: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #B43D3D;
    border-radius: 20px;
    padding: 20px;
    font-size: 14px;
}

.success {
    position: absolute;
    display: flex;
    top: 90%;
    left: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #63C269;
    border-radius: 20px;
    padding: 20px;
    font-size: 14px;
}

.top-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 95%;
}

.details {
    padding-right: 20px;
    color: #22272C;
    padding-top: 10px;
    padding-bottom: 5px;
    cursor: pointer;
}

.your-team {
    background-color: #00BBEA;
    padding: 20px;
    border-radius: 10px;
    width: 30%;
    justify-content: center;
    font-size: 18px;
}

.progress-div {
    /* background-color: rgb(233, 233, 233); */
    background-color: #EAE4CA;
    border-radius: 2rem;
    height: 40px;
}

.orange-progress {
    /* background-color: rgb(62, 122, 235); */
    height: 40px;
    background-image: linear-gradient(to right, #ecc546 , #ca4e14);
    border-radius: 5rem;
    transition: 1s ease;
    transition-delay: 0.5s;
  }

.blue-progress {
    /* background-color: rgb(62, 122, 235); */
    height: 40px;
    background-image: linear-gradient(to right, #00BBEA , #142766);
    border-radius: 5rem;
    transition: 1s ease;
    transition-delay: 0.5s;
  }

.inline {
    display: inline;
    padding-left: 20px;
}

.inline-2{
    display: inline;
}

.switch {
    background-color: black;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-bottom: 5%;
    width: 350px;
}

.switch > div {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 8px;
    text-align: center;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-direction: row;
}

@media (min-width: 1200px) {
    .hide-on-desktop {
        display: none !important;
    }

    .footer {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0%;
        left: 2%;
        font-size: 18px;
        flex-direction: row;
    }
}

@media (max-width: 800px) {
    .teams{
        flex-direction: column;
    }

    .blue, .orange {
        margin-bottom: 20px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .full-nav {
        display: none;
    }

    .next-airdrop {
        color: white;
    }

    .hide-on-mobile {
        display: none !important;
    }
}
