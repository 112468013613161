@import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');

body {
    margin: 0;
    /* font-family: 'Inter', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #ffffff;
    background: url(./images/moonrace_background_small.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100vw;
    height: 100vh;
}

.buy {
    background-image: url(./images/swap_background.png) !important;
}

.swap {
    background-image: url(./images/moon_logo.png) !important;

}

nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 2rem;
}

nav > div {
    display: flex;
    align-items: center;
}

nav > div > button {
    margin-left: 1rem;
}

@media (max-width: 600px) {
    /* nav {
        display: none;
    } */
    nav > h1 {
        max-width: 90px;
    }
}